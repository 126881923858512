<template>
  <v-container>
    <!-- <v-row class="web_version" v-if="!$vuetify.breakpoint.mobile">
      Web Version
    </v-row> -->
    <v-row class="app_version">
      <!-- ************************************************************************************ -->
      <!-- *** Wallet Start ******************************************************************* -->
      <v-col cols="12" class="pa-0" :key="someVariableUnderYourControl">
        <!-- <h6 class="subheader grey--text"></h6> -->
        <div v-if="login_yn == true">
          <!-- <Wallet :cash.sync="own_data.cash" -->
          <Wallet :toWallet="cashData" :key="childKey"> </Wallet>
        </div>
        <div v-else>
          <!-- <Wallet :cash.sync="no_data.cash" -->
          <Wallet> </Wallet>
          <v-divider class="ml-3 mr-3"></v-divider>
        </div>
      </v-col>
    </v-row>

    <div>
      <v-col v-if="login_yn == true" class="" cols="12">
        <div class="home_gifticon pa-0">
          <div class="banner" @click="goBlog">
            <img src="/imgs/banner.png" alt="배너이미지" width="100%" />
          </div>

          <div class="slider pb-0">
            <carousel-3d
              :key="forceUpdate"
              :perspective="0"
              :space="330"
              :display="sliders.length"
              :width="260"
              :height="150"
              ref="carousel"
              @after-slide-change="onAfterSlideChange"
            >
              <slide
                v-for="(item, index) in sliders"
                :index="index"
                :key="index"
                :class="item.className"
              >
                <!-- :style="`background: ${item.product_img}`" -->
                <template v-if="item.className !== 'add-gifticon'">
                  <img
                    class="p-crop-img"
                    :data-index="index"
                    :src="item.product_img"
                    @click="setPreview(item)"
                  />

                  <line
                    x1="9.5"
                    y1="1.96701e-08"
                    x2="9.5"
                    y2="19"
                    stroke="#666666"
                  />
                  <line x1="19" y1="9.5" y2="9.5" stroke="#666666" />
                  <!-- </svg> -->
                </template>

                <template v-else>
                  <div>
                    <a href="#" style="text-decoration: none;">
                      <label for="myfileid">
                        <div style="color: #fff">기프티콘 추가</div>
                      </label>
                    </a>
                    <v-file-input
                      type="file"
                      v-model="selected_gift"
                      name="something"
                      id="myfileid"
                      style="display: none"
                      accept="image/png, image/jpeg, image/bmp"
                      @change="selectedGift"
                    />
                  </div>
                </template>
              </slide>
            </carousel-3d>

            <p>
              지갑 속 기프티콘
              <span
                >( {{ current_slide_num + 1 }} /
                {{ sliders.length - 1 }} )</span
              >
            </p>
          </div>

          <div class="history pb-0">
            <v-btn plain @click="UseHistory">
              <p>
                <svg
                  width="17"
                  height="12"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8795 6C13.8795 7.17128 14.8269 8.12217 16 8.12217V11H1V8.12217C2.17315 8.12217 3.12045 7.17128 3.12045 6C3.12045 4.82872 2.17315 3.87154 1 3.87154V1H16V3.87154C14.8269 3.87154 13.8795 4.82242 13.8795 6Z"
                    stroke="#555555"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                사용내역
              </p>
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.24264 0.757359L5.48528 5L1.24264 9.24264"
                  stroke="#777777"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </div>
      </v-col>

      <!-- *** Wallet End ********************************************************************* -->
      <!-- ************************************************************************************ -->

      <v-col cols="12" class="mt-2 main_transaction">
        <v-tabs
          v-model="home_tabs"
          color="white"
          class="mt-0 tab"
          style="border-radius: 5px;"
          background-color="primary2 accent-4"
          fixed-tabs
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab href="#map" class="after-bar" @click="mapInfo"> 내주변 </v-tab>
          <v-tab href="#list" @click="listInfo(0)"> 기프티콘 </v-tab>
        </v-tabs>
        <!-- NTF -->

        <v-tabs-items v-model="home_tabs" touchless class="mt-2" ref="alert">
          <v-tab-item value="map">
            <div class="text-center map_wrap">
              <div
                id="map2"
                style="
                  width: 100%;
                  height: 100%;
                  position: relative;
                  overflow: hidden;
                  border-radius: 10px;
                "
              ></div>
              <div
                class="custom_typecontrol radius_border"
                @click="setPosition"
              >
                <span id="btnRoadmap" class="selected_btn pr-4" style="z-index: 999;">현위치</span>
              </div>
            </div>
          </v-tab-item>

          <!-- Product-List **************************************************************-->
          <v-tab-item value="list" class="mb-1">
            <div v-if="!showNoItem" class="coffee">
              <ProductCard
                class="ml-1 mr-1 mb-2"
                v-for="(row, i) in giftcard_datas"
                :row="row"
                :key="i"
                @click.native="goProductDetail(row)"
              />
              <!-- @childs-event="trackingQnt" -->
            </div>

            <img
              v-if="showNoItem"
              src="https://res.cloudinary.com/dw4yrr8eq/image/upload/v1649061847/noitem_qogzsn.svg"
              style="max-width: 100%"
              class="ma-12"
            />

            <!-- @childs-event="showDialog" -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <!-- ********************************************************************************* -->
      <!-- Dialog : Register Start ********************************************************* -->
      <v-dialog
        v-model="confirm_dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-container fluid class="pa-6" style="background-color: white">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <img
                      id="barcodeImage"
                      :src="selected_img"
                      style="width: 80vw; padding-bottom: 10px"
                    />
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="12" class="">
                    <v-text-field
                      @click.once="showBarcode()"
                      class="centered-input"
                      v-model="barcodeNumber"
                      label="바코드"
                      placeholder="바코드를 입력해주세요"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      label="브랜드"
                      v-model="brand_name"
                      placeholder="브랜드 이름을 입력해주세요 예, 스타벅스"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      label="상품명"
                      v-model="product_name"
                      placeholder="상품 이름을 입력해주세요 예, 아메리카노"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      label="유효기간"
                      v-model="gifticonExpire"
                      placeholder="언제까지 사용가능한가요?"
                      readonly
                      @click="showCalendar"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-center mb-16">
                  <v-btn
                    color="grey"
                    class="mr-3 white--text"
                    @click="cancelsaveToServerDialog"
                  >
                    취소</v-btn
                  >

                  <v-btn color="primary" @click="saveToServer">등록 </v-btn>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-dialog>
      <!-- Dialog : Register End ***************************************************** -->
      <!-- *************************************************************************** -->

      <!-- *************************************************************************** -->
      <!-- Dialog : Calendar Start ***************************************************** -->
      <v-dialog
        v-model="showCalendar_mo"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-container fluid class="pa-6" style="background-color: white">
            <v-row class="justify-center">
              <v-date-picker
                v-model="gifticonExpire"
                locale="ko"
                full-width
              ></v-date-picker>
            </v-row>

            <v-row class="justify-center">
              <v-btn color="primary" text @click="showCalendar_mo = false">
                확인
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Dialog : Calendar End ***************************************************** -->
      <!-- *************************************************************************** -->

      <!-- **************************************************************************** -->
      <!-- Dialog : Gifti List Start  **************************************************-->
      <v-dialog
        v-model="gift_dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        persistent
        hide-overlay
        transition="dialog-bottom-transition"
        style="z-index: 99"
      >
        <GiftDetailModal @hide="hideDialog" :item.sync="gift_detail_item">
        </GiftDetailModal>
      </v-dialog>
      <!-- Dialog : Gifti List End  ***************************************************-->
      <!-- **************************************************************************** -->

      <!-- ********************************************************************************** -->
      <!-- Dialog : preview ***************************************************************** -->
      <v-dialog
        v-model="preview_dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-container fluid class="pa-6" style="background-color: white">
            <v-row>
              <v-col class="text-center">
                <img :src="preview_item.product_img" style="width: 25vw" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-center">
                <barcode v-if="barcodeNumber" :value="barcodeNumber">
                  Show this if the rendering fails.
                </barcode>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- <div v-if="preview_item.barcode">{{preview_item.barcode}}</div> -->
                <div v-if="preview_item.title">
                  {{ preview_item.title }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div v-if="preview_item.expire_date">
                  유효기간 : {{ preview_item.expire_date }}
                </div>
              </v-col>
            </v-row>

            <v-row class="justify-space-around pa-3">
              <v-btn
                color="grey lighten-3"
                width="120"
                elevation="0"
                @click="canceltPreviewDialog"
              >
                취소
              </v-btn>
              <v-btn color="primary" width="120" @click="updatePreviewDialog">
                사용완료
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Dialog End : preview ************************************************************* -->
      <!-- ********************************************************************************** -->

      <!-- *************************************************************************** -->
      <!-- snackbar ****************************************************************** -->
      <v-snackbar v-model="snackbar">
        {{ snackbar_msg }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="closePage">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay :value="loading_bar">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- ****************************************************************************** -->
      <!-- Alert : Close Browser  ******************************************************* -->
      <v-alert v-if="showAlert" @click="closeAlert" type="success"></v-alert>
      <!-- Alert : Close Browser End  *************************************************** -->
      <!-- ****************************************************************************** -->

      <!-- *************************************************************************** -->
      <!-- Dialog : Calendar Start ***************************************************** -->
      <v-dialog
        v-model="test_dialog"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-card-text>{{ test_dialog_content }} </v-card-text>
          <v-btn color="blue" text @click="test_dialog = false">
            Close
          </v-btn>
        </v-card>
      </v-dialog>
      <!-- Dialog : Calendar End ***************************************************** -->
      <!-- *************************************************************************** -->

      <v-dialog
        v-model="fake_gift_dialog"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-card-text>{{ fake_gift_content }} </v-card-text>
          <v-btn color="blue" text @click="fake_gift_dialog = false">
            Close
          </v-btn>
        </v-card>
      </v-dialog>

      <!-- *************************************************************************** -->
      <!-- is Present ***************************************************** -->
      <v-dialog
        v-model="isPresented"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-card-title class="text-h5 justify-center"> 알림 </v-card-title>
          <v-card-text>기프티콘 지갑에 선물이 도착했습니다</v-card-text>
          <v-card-text class="text-center">
            <v-btn color="primary" rounded large @click="confirmPresent">
              확인
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- is Present End ************************************************ -->
      <!-- Dialog : Notice Start ***************************************************** -->
      <v-dialog v-model="notice_dialog" max-width="290">
        <!-- :fullscreen="$vuetify.breakpoint.mobile" -->
        <v-card>
          <v-card-title class="text-h5"> 공지사항 </v-card-title>

          <v-card-text v-html="notice_content"> </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="notice_dialog = false">
              닫기
            </v-btn>
            <v-btn color="green darken-1" text @click="setNever">
              다시보지않기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog : Notice End ***************************************************** -->
      <!-- *************************************************************************** -->

      <!-- *************************************************************************** -->
      <!-- Dialog : Get Server Start  ******************************************************* -->
      <v-dialog v-model="landscape_dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 안내 </v-card-title>

          <v-card-text> 가로보기 지원을 하지 않습니다 </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="landscape_dialog = false"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
/*global kakao*/
//import { mapState, mapActions } from 'vuex'
import axios from "../../service/axios";
import VueBarcode from "vue-barcode";
import prj_config from "../../helper/global_config.js";
import { Carousel3d, Slide } from "vue-carousel-3d";
// import * as Hammer from "hammerjs";

export default {
  name: "Home",
  components: {
    // GiftCard: () => import("@/components/card/GiftCard"),
    ProductCard: () => import("@/components/card/ProductCard"),
    GiftDetailModal: () => import("@/components/modal/GiftDetailModal"),
    Wallet: () => import("@/components/wallet/Wallet"),
    barcode: VueBarcode,
    Carousel3d,
    Slide,
  },

  data() {
    return {
      someVariableUnderYourControl: 0,
      btn_group: 0,
      home_tabs: null,
      favorite_datas: [],
      own_data: {
        cash: null,
        own_gift_datas: [],
      },
      myOwnGifts: [],
      no_data: {
        cash: 1000,
        no_gift_datas: [
          {
            // product_img: '/imgs/no_login_primary.png'
            product_img:
              "https://www.circleone.in/images/products_gallery_images/PVC-Banner.jpg",
          },
        ],
      },
      giftcard_datas: [],
      category: [
        {
          name: "커피",
        },
        {
          name: "아이스크림",
        },
        {
          name: "빵",
        },
      ],
      gift_dialog: false,
      gift_detail_item: null,
      // login_yn: false,
      preview_dialog: false,
      preview_item: {
        src: null,
        product_name: null,
        brand_name: null,
        org_price: 0,
        expire_date: null,
        barcodeNumber: null,
      },
      position: "",
      snackbar: false,
      snackbar_msg: null,
      loading_bar: false,
      isLoading: false,
      mapOptions: {
        zoom: 17,
        lat: 37.5110002,
        lng: 127.0571981,
      },
      store_data: [],
      selected_gift: null,
      mapContainer: "",
      lat: 37.4773302,
      lng: 126.9805346,
      map: "",
      zoomControl: null,
      // 사당사거리 37.4773302,126.9805346,
      shops: [],

      current_unit: "달러",
      confirm_dialog: false,
      selected_img: null,
      barcodeNumber: null,
      brand_name: null,
      product_name: null,
      org_price: 0,
      gifticonExpire: null,
      barcodeCanvas: null,
      showCalendar_mo: false,
      showAlert: false,
      test_dialog: false,
      test_dialog_content: "TEST",
      fake_gift_dialog: false,
      fake_gift_content: "사용이 완료된 기프티콘입니다",
      notice_dialog: false,
      notice_content: null,
      landscape_dialog: false,
      version: null,

      bottom_ads:
        "https://res.cloudinary.com/mothcar/image/upload/v1635303190/otc_img/neko_ads.jpg",
      chooseImage: null,

      showNoItem: false,
      old_infowindows: [],
      cashData: null,
      childKey: 0,
      sliders: [],
      current_slide_num: 0,
      dragStartX: 0,
      dragStartY: 0,
      forceUpdate: 0,
      isPresented: false,
    };
  },

  props: [],

  computed: {
    // ...mapState('G_NAME', [])
    login_yn() {
      let flag = this.$store.getters.getUserData;

      if (flag) {
        return true;
      } else return false;
    },

    guideImg() {
      if (this.myOwnGifts.length == 0) return true;
      else return false;
    },
  },

  watch: {
    //해당 라우트에서 주소가 바꼈을시 호출됨
    // $route(to, from) {
    //   console.log("watch !!!! (from) : ", from);
    //   this.childKey += 1;
    //   if (
    //     from.name === "sendGift" ||
    //     from.name === "gift_preview" ||
    //     from.name === "product_detail" ||
    //     from.name === "sellGift" ||
    //     from.name === "login"
    //   ) {
    //     this.someVariableUnderYourControl+=1
    //     this.req_load_my_gift();
    //     // this.$forceUpdate()
    //     this.listInfo();
    //     this.lookStorage();
    //     console.log("Send gift : ");
    //   }
    // },
    $route(to, from) {
      // console.log("watch !!!! (from) : ", from);
      // this.childKey += 1;

      if (
        from.name === "gift_preview" ||
        from.name === "product_detail" ||
        from.name === "login"
      ) {
        // this.req_load_my_gift();
        // // this.$forceUpdate()
        // this.listInfo();
        // this.lookStorage();
        location.reload();
      }
    },
    home_tabs(val) {
      if (val === "list") {
        return this.listInfo(0);
      }

      const script = document.createElement("script");
      // script.src = '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&libraries=services&appkey=' + process.env.VUE_APP_KAKAO_MAP_KEY;
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_MAP_KEY}`;
      // autoload=false&
      document.head.appendChild(script);
      script.onload = () => {
        kakao.maps.load(this.initKakaoMap);
        this.setGeoLocation();
      };
    },
  },

  async created() {
    // console.log(`%c Home Created `, "color:blue; font-weight:bold;");

    this.$store.commit({ type: "setSubPage", sub_page: "main" });
    const raw_notice = await axios.get("/notice/getHeadNotice");
    const notice = raw_notice.data.data.item[0];
    const old_notice = this.$store.getters.getNotice;
    this.version = notice.version;
    let line = notice.contents;
    let interpreteNotice = line.replace(/(?:\r\n|\r|\n)/g, "<br />");

    window.addEventListener("resize", this.resizeEventHandler);

    if (old_notice.old_ver != null) {
      if (notice.version > old_notice.old_ver) {
        // this.notice_dialog = true;
        this.notice_dialog = false;
        this.notice_content = interpreteNotice;
      } else {
        if (old_notice.check == false) {
          // this.notice_dialog = true;
          this.notice_dialog = false;
          this.notice_content = interpreteNotice;
        }
      }
    } else {
      // this.notice_dialog = true;
      this.notice_dialog = false;
      this.notice_content = interpreteNotice;
    }
    this.home_tabs = this.$store.getters.getHomeState;
  }, // create

  mounted() {
    // console.log("Guide Img : ", this.myOwnGifts);
    const token = this.$cookies.get("token");
    window.popStateDetected = false;
    window.addEventListener("popstate", () => {
      window.popStateDetected = true;
    });

    let global_config = prj_config;
    // console.log('Global ..............: ', global_config)
    this.current_unit = global_config.current_unit;

    if (token != null) {
      // this.lookStorage();
      this.getLedger();
      // this.req_load_my_gift();
      this.request_reward();
      this.resizeEventHandler();
      this.req_load_my_gift();
    }
  }, // mounted

  methods: {
    //...mapActions('G_NAME', ['setArrow', 'setTab', 'setMode', 'setCurrentAddress']),
    // loadKakaoMap()   {
    // }, // loadKakaoMap
    confirmPresent() {
      this.isPresented = false;
    },

    async lookStorage() {
      const token = this.$cookies.get("token");
      if (token != null) {
        let isGift = localStorage.getItem("Gift");
        if (isGift) {
          const params = {
            accessKey: token,
            gift_id: isGift,
          };

          const fromServer = await axios.post("/gift/receiveGift", params);
          // console.log("Save Gift .................... : ", fromServer);
          const isNull = fromServer.data.data.item;
          if (isNull === null) {
            this.fake_gift_dialog = true;
          }
          localStorage.removeItem("Gift");
        }
      }
    },
    async req_load_my_gift() {
      var options = {
        del_yn: false,
      };
      const params = {
        accessKey: this.$cookies.get("token"),
        options: options,
      };

      await axios
        .post("/gift/list", params)
        .then((res) => {
          var gifts = res.data.data.item;

          let tempGift = [];
          for (var gift of gifts) {
            gift["gift_type"] = "mygift";
            gift["product_img"] = gift["gift_img_url"];

            gift["name"] = "giftName";
            gift["background"] = "#fdf";
            gift["className"] = "aa";
            if (gift.is_nft == true) this.isPresented = true;
            // this.own_data.own_gift_datas.push(gift);
            tempGift.push(gift);
          }
          tempGift.push({
            article_code: "",
            name: "기프티콘 추가",
            background: "#fff",
            className: "add-gifticon",
          });

          this.$store.commit({ type: "setMyGifts", myGifts: tempGift });
          this.myOwnGifts = this.$store.getters.getMyGifts;
          this.sliders = this.$store.getters.getMyGifts;
          this.forceUpdate += 1;
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    },

    async request_reward() {
      // .post("/today
      // accessKey
      const token = this.$cookies.get("token");
      const params = {
        accessKey: token,
      };

      await axios.post("/reward/today", params);

    },

    goBlog() {
      let path = "https://blog.naver.com/onethecon/222724888732";
      window.open(path, "_blank");
    },

    async getLedger() {
      const token = this.$cookies.get("token");
      const params = {
        accessKey: token,
      };
      // console.log("wallet params : ", params);
      await axios
        .get("/user_account/getWalletInfo", { params: params })
        .then((res) => {
          // console.log("Result : ", this.$store.getters.getUserData);
          let serverCash = res.data.data.item;
          this.cashData = res.data.data.item;
          this.$store.commit({ type: "setBalance", balance: serverCash });
          // console.log("Res : ", res.data.data.item);
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    }, // getLedger

    setGeoLocation() {
      var _this = this;
      // current-position
      function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          // console.log("Geolocation is not supported by this browser.");
        }
      }
      function showPosition(position) {
        var moveLatLon = new kakao.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        // _this.map.panTo(moveLatLon);
        _this.$store.commit({
          type: "setCurrentPosition",
          current_position: moveLatLon,
        });
      }
      getLocation();
    },

    goProductDetail(item) {
      // console.log("Click item ...........: ", item);
      this.$store.commit({ type: "setSubPage", sub_page: "상품안내" });
      this.$store.commit({ type: "setProduct", product: item });
      this.$router.push({
        name: "product_detail",
        params: { data: item },
      });
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.landscape_dialog = true;
      }
    },

    setNever() {
      const params = {
        old_ver: this.version,
        check: true,
      };
      this.$store.commit({ type: "setNotice", notice: params });
      this.notice_dialog = false;
    }, // setNever()

    async setPosition() {
      const moveLatLon = this.$store.getters.getCurrentPosition;
      this.map.panTo(moveLatLon);
      this.map.setLevel(4, {
        animate: {
          duration: 800,
        },
      });
    },

    closeAlert() {
      this.showAlert = false;
    },

    goLogin() {
      this.login();
    },

    goAdsLink() {
      // let path = 'https://www.mois.go.kr/2021emgnc/main.html'
      let path = "https://cat-dog-friendly.com";
      // this.$router.push(path)

      // let routeData = this.$router.resolve({name: path, query: {data: "someData"}});
      // window.open(routeData.href, '_blank');

      // let route = this.$router.resolve({ path: path });
      window.open(path, "_blank");
    },

    showCalendar() {
      this.showCalendar_mo = true;
    },

    initKakaoMap() {
      // if state : getCenterCoordi == null get normal coordi
      // else get state coordi
      let Coordi = this.$store.getters.getCenterCoordi;
      let store_level = this.$store.getters.getZoomLevel;
      //let newCoordi = JSON.parse(Coordi)
      let lat = this.lat;
      let lng = this.lng;
      let level = 3;

      if (Coordi != null) {
        lat = Coordi.Ma;
        lng = Coordi.La;
      }
      if (store_level != null) {
        level = store_level;
      }

      var container = document.getElementById("map2");
      var options = {
        center: new kakao.maps.LatLng(lat, lng),
        level: level,
      };

      var map = new kakao.maps.Map(container, options);
      this.map = map;

      this.getShops();

      let _this = this;

      kakao.maps.event.addListener(this.map, "idle", function() {
        //let strCenter = JSON.stringify(center)
        let center = _this.map.getCenter();
        let level = _this.map.getLevel();
        _this.$store.commit({
          type: "setCenterCoordi",
          center_coordi: center,
        });
        _this.$store.commit({
          type: "setZoomLevel",
          zoom_level: level,
        });
        _this.getShops();
      });
      this.setGeoLocation();
      this.map.relayout();
    },

    getShops() {
      var bounds = this.map.getBounds();
      var bound_bottom_lat = bounds.qa;
      var bound_bottom_lng = bounds.ha;
      var bound_top_lat = bounds.pa;
      var bound_top_lng = bounds.oa;

      let queryParams = {
        b_lat: bound_bottom_lat,
        b_lng: bound_bottom_lng,
        t_lat: bound_top_lat,
        t_lng: bound_top_lng,
      };

      var old_iws = this.old_infowindows;
      var set_old_iws = [];

      axios
        .get("/shop/getShops", { params: queryParams })
        .then((res) => {
          if (old_iws.length > 0) {
            for (var k = 0; k < old_iws.length; k++) {
              old_iws[k].setMap(null);
            }
          }

          let positions = res.data.data.item;

          for (var i = 0; i < positions.length; i++) {
            // 마커 이미지의 이미지 크기 입니다
            var imageSize = new kakao.maps.Size(24, 35);
            var imageSrc = positions[i].image;

            // 마커 이미지를 생성합니다
            var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

            var lat = positions[i].location.coordinates[1];
            var lng = positions[i].location.coordinates[0];
            var latlng = this.set_standard_coords(lat, lng);

            // 마커를 생성합니다
            var marker = new kakao.maps.Marker({
              map: this.map, // 마커를 표시할 지도
              position: latlng, // 마커를 표시할 위치
              // title : positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
              image: markerImage, // 마커 이미지
              clickable: true,
            });

            var data = positions[i];

            marker.setMap(this.map);

            set_old_iws[i] = marker;

            var _this = this;
            (function(marker, data) {
              kakao.maps.event.addListener(marker, "click", function() {
                _this.markerClick(data);
              });
            })(marker, data);
          }
          this.old_infowindows = set_old_iws;
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    },

    markerClick(data) {
      this.$store.commit({ type: "setSubPage", sub_page: "상품구매" });
      this.$store.commit({ type: "setShopDetail", shopDetail: data });
      this.$router.push({
        // path:'/detail/shopDetail',
        name: "shop_detail",
        params: { data: data },
      });
      this.$store.commit({
        type: "setCurrentRoute",
        route: "shop_detail",
      });
    },

    set_standard_coords(lat, lng) {
      let latlng = new kakao.maps.LatLng(lat, lng);
      return latlng;
    },

    initgiftcard() {
      this.giftcard_datas.splice(0, this.giftcard_datas.length);
      this.giftcard_datas = [];
    },

    formatPrice(value) {
      try {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } catch {
        return value;
      }
    },

    showDialog(item) {
      // console.log("Item.... : ", item);
      this.gift_detail_item = item;
      this.gift_dialog = true;
    },

    hideDialog() {
      this.gift_detail_item = null;
      this.gift_dialog = false;
    },

    cancelsaveToServerDialog() {
      this.barcodeNumber = null;
      this.gifticonExpire = null;
      this.selected_gift = null;
      this.confirm_dialog = false;
      (this.brand_name = null), (this.product_name = null);
    },

    setPreview(item) {
      this.$store.commit({
        type: "setSubPage",
        sub_page: "상품상세",
      });
      this.$router
        .push({ name: "gift_preview", params: { item: item } })
        .catch(() => {}); // item: {gift}
    },

    canceltPreviewDialog() {
      this.preview_dialog = false;
    },

    updatePreviewDialog() {
      this.preview_dialog = false;
      let options = {
        _id: this.preview_item._id,
        del_yn: true,
      };
      const params = {
        accessKey: "",
        options: options,
      };

      if (this.preview_item.gift_type == "mygift") {
        axios
          .post("/gift/delete", params)
          .then((res) => {
            console.log(res.data.data);
            this.own_data.own_gift_datas = [];
            // this.ownGiftInfo();
            // this.req_load_my_gift();
          })
          .catch((error) => {
            console.log("페스트 오류", error);
          });
      }
    },

    login() {
      this.$store.commit({ type: "setSubPage", sub_page: "로그인" });
      this.$router.push("/sign/login");
      this.$store.commit({
        type: "setCurrentRoute",
        route: "login",
      });
    },

    signUp() {
      this.$store.commit({ type: "setSubPage", sub_page: "회원가입" });
      this.$router.push("/sign/signin");
      this.$store.commit({
        type: "setCurrentRoute",
        route: "signin",
      });
    },

    mapInfo() {
      this.$store.commit({ type: "setHomeState", home_state: "map" });
    },

    async ownGiftInfo() {
      const params = {
        accessKey: this.$cookies.get("token"),
        options: false,
      };
      await axios
        .post("/product/ownProductInfo", params)
        .then((res) => {
          this.loading_bar = false;
          let tempGift;
          for (var gift of res.data.data) {
            gift["gift_type"] = "product";
            this.own_data.own_gift_datas.push(gift);
            tempGift.push(gift);
          }
          this.myOwnGifts = tempGift;
        })
        .catch((error) => {
          this.loading_bar = false;
          console.log("페스트 오류", error);
        });
    },

    req_store_list() {
      const params = {
        accessKey: this.$cookies.get("token"),
      };
      axios
        .post("/shop/list", params)
        .then((res) => {
          this.store_data = res.data.data.item;
        })
        .catch((error) => {
          this.loading_bar = false;
          console.log("페스트 오류", error);
        });
    },

    async listInfo(next_key) {
      if (next_key == 0) {
        this.$store.commit({
          type: "setHomeState",
          home_state: "list",
        });
        this.initgiftcard();
      }

      if (this.$cookies.get("token") != null) {
        // this.favoriteInfo();
      }

      const params = {
        next_key: next_key,
      };

      let articleList = await axios.get("/article/getArticleList", {
        params: params,
      });
      let productList = await axios.get("/product/getProductList", {
        params: params,
      });
      // console.log("Get product list : ", productList.data.data.item);
      let articleItems = articleList.data.data.item;
      let productItems = productList.data.data.item;

      var items = articleItems.concat(productItems);
      if (items.length > 0) {
        this.giftcard_datas = items;
      } else {
        this.showNoItem = true;
      }
      this.isLoading = false;
    },

    favoriteInfo() {
      const params = {
        accessKey: this.$cookies.get("token"),
      };
      axios
        .post("/api/product/favoriteList", params)
        .then((res) => {
          var arr = res.data.data;
          this.favorite_datas = arr.reduce(
            (obj, item) => Object.assign(obj, { [item.product_id]: item._id }),
            {}
          );
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    },

    closePage() {
      this.snackbar = false;
    },
    favoriteDisable() {
      this.snackbar_msg = "로그인 전에는 관심 기프티콘을 등록하실 수 없습니다.";
      this.snackbar = true;
    },

    scroll() {
      window.onscroll = () => {
        var totalPageHeight = document.body.scrollHeight;
        var scrollPoint = window.scrollY + window.innerHeight;
        let off = totalPageHeight - scrollPoint;

        // if(scrollPoint >= totalPageHeight || off <= 50) {
        //     this.req_theme_data(this.next_token);
        // }

        if (this.isLoading == false && off <= 300) {
          try {
            var last_data = this.giftcard_datas[this.giftcard_datas.length - 1];
            this.isLoading = true;

            var next_key = last_data["_id"];
            this.listInfo(next_key);
          } catch (ex) {
            console.log(ex);
          }
        }
      };
    },

    navermap_load() {},

    selectedGift() {
      if (this.selected_gift != null) {
        this.chooseImage = URL.createObjectURL(this.selected_gift);
        // PREVIEW IMAGE
        this.selected_img = URL.createObjectURL(this.selected_gift);
        this.confirm_dialog = true;
      }
    },
    async showBarcode() {
      if (!("BarcodeDetector" in window)) {
        // console.log(
        //     "Barcode Detector is not supported by this browser."
        // );
      } else {
        // eslint-disable-next-line no-undef
        var barcodeDetector = new BarcodeDetector({
          formats: [
            "aztec",
            "code_128",
            "code_39",
            "code_93",
            "codabar",
            "data_matrix",
            "ean_13",
            "ean_8",
            "itf",
            "pdf417",
            "qr_code",
            "upc_a",
            "upc_e",
          ],
        });

        const bar_img = document.getElementById("barcodeImage");
        await barcodeDetector
          .detect(bar_img)
          .then((barcodes) => {
            // console.log("barcode Test.... barcodes : ", barcodes);
            this.barcodeNumber = barcodes[0].rawValue;
          })
          .catch(console.error);
      } // else
    },
    onAfterSlideChange(index) {
      this.current_slide_num = index;

      if (index === this.sliders.length - 1) {
        this.current_slide_num = this.sliders.length - 2;
      }
    },
    handleMouseup(e) {
      const { currentIndex } = this.$refs.carousel;
      const { deltaX, deltaY } = window;

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        return;
      }

      if (deltaX > this.$refs.carousel.minSwipeDistance) {
        this.$refs.carousel.currentIndex =
          currentIndex === this.sliders.length - 1 ? 0 : currentIndex + 1;
      } else if (deltaX < -this.$refs.carousel.minSwipeDistance) {
        this.$refs.carousel.currentIndex =
          currentIndex === 0 ? this.sliders.length - 1 : currentIndex - 1;
      }

      this.$refs.carousel.handleMouseup(e);
    },

    resizeEventHandler() {
      const isMobile = true;
      this.$refs.carousel.$el.removeEventListener(
        "touchstart",
        this.$refs.carousel.handleMousedown
      );
      this.$refs.carousel.$el.removeEventListener(
        "touchend",
        this.handleMouseup
      );
      this.$refs.carousel.$el.removeEventListener(
        "touchmove",
        this.$refs.carousel.handleMousemove
      );
      this.$refs.carousel.$el.removeEventListener(
        "mousedown",
        this.$refs.carousel.handleMousedown
      );
      this.$refs.carousel.$el.removeEventListener(
        "mouseup",
        this.$refs.carousel.handleMouseup
      );
      this.$refs.carousel.$el.removeEventListener(
        "mousemove",
        this.$refs.carousel.handleMousemove
      );

      if (isMobile) {
        // console.log(
        //   `%c ${this.$refs.carousel}`,
        //   "color:yellow; font-weight:bold;"
        // );
        this.$refs.carousel.$el.addEventListener("touchstart", (e) => {
          this.dragStartX = e.touches[0].clientX;
          this.dragStartY = e.touches[0].clientY;

          this.$refs.carousel.handleMousedown(e);
        });

        this.$refs.carousel.$el.addEventListener(
          "touchend",
          this.handleMouseup
        );

        this.$refs.carousel.$el.addEventListener("touchmove", (e) => {
          window.eventPosX = e.touches[0].clientX;
          window.eventPosY = e.touches[0].clientY;
          window.deltaX = this.dragStartX - window.eventPosX;
          window.deltaY = this.dragStartY - window.eventPosY;
        });
      } else {
        this.$refs.carousel.$el.addEventListener(
          "mousedown",
          this.$refs.carousel.handleMousedown
        );
        this.$refs.carousel.$el.addEventListener(
          "mouseup",
          this.$refs.carousel.handleMouseup
        );
        this.$refs.carousel.$el.addEventListener(
          "mousemove",
          this.$refs.carousel.handleMousemove
        );
      }
    },
    async saveToServer() {
      this.confirm_dialog = false;
      this.loading_bar = true;

      let formData = new FormData();
      formData.append("file", this.selected_gift);

      var upload_url = null;
      formData.append("upload_preset", "khtvl2yr");
      const imageData = await axios.post(
        "https://api.cloudinary.com/v1_1/mothcar/upload",
        formData
      );
      // const imageData = await axios.post('https://api.cloudinary.com/v1_1/mothcar/image/upload', formData)
      if (imageData) {
        // console.log("이미지 업로드", imageData);
        upload_url = imageData.data.url;
        // console.log("이미지 url :", upload_url);
        this.selected_gift = null;
      }

      if (upload_url != null) {
        await this.req_save_my_gift(upload_url);
      }

      await this.giftSort();
    },

    async req_save_my_gift(gift_img_url) {
      let setBrandName = this.brand_name;
      if (setBrandName == null) {
        setBrandName = "브랜드명 없음";
      }
      let setProductName = this.product_name;
      if (setProductName == null) {
        setProductName = "상품명 없음";
      }
      const params = {
        accessKey: this.$cookies.get("token"),
        barcode: this.barcodeNumber,
        gift_img_url: gift_img_url,
        brand_name: setBrandName,
        product_name: setProductName,
        expire_date: this.gifticonExpire,
      };
      await axios
        .post("/gift/save", params)
        .then((res) => {
          var data = res.data.data;
          console.log(data);
          this.own_data.own_gift_datas = [];
          this.loading_bar = false;
          location.reload();
          this.cancelsaveToServerDialog();
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    },

    go_brand_product_list_view(brand) {
      this.$store.commit({ type: "setSubPage", sub_page: brand });
      this.$router.push({ name: "brand", query: { brand: brand } });
      this.$store.commit({
        type: "setCurrentRoute",
        route: "brand",
      });
    },

    UseHistory() {
      this.$store.commit({ type: "setSubPage", sub_page: "사용내역" });
      this.$router.push("/detail/delhistory");
      this.$store.commit({
        type: "setCurrentRoute",
        route: "delhistory",
      });
    },

    async giftSort() {
      await this.own_data.own_gift_datas.sort(function(a, b) {
        // return a._id > b._id ? -1 : a._id < b._id ? 1 : 0;
        if (a.create_date > b.create_date) {
          return -1;
        } else if (a.create_date < b.create_date) {
          return 1;
        } else if (
          a.create_date == b.create_date &&
          a.create_time > b.create_time
        ) {
          return -1;
        } else {
          return 1;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 500px) {
  .web_version {
    // display: none;
    max-width: 500px;
  }
}
@media screen and (min-width: 501px) {
  .app_version {
    // display: none;
    max-width: 500px;
  }
}

.img-container {
  overflow: hidden;
  height: 100px;
  max-height: 300px;
  position: relative;
}

.p-crop-img {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  // top: -50%;
  position: relative;
  transform: translateY(-40%);
}

.crop-img {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: -60px;
}

.crop-img2 {
  display: block;
  width: 40%;
  height: auto;
  position: absolute;
  top: 1px;
  right: 1px;
}

.crop-img3 {
  display: block;
  position: absolute;
  font-size: 1.2em;
  color: #fff;
  top: 11px;
  right: 27px;
}

.preview_img {
  .v-image__image--cover {
    background-size: contain;
  }
}
.preview {
  .v-btn--icon.v-size--small {
    width: 10px;
    height: 10px;
  }
}
.subheader {
  font-size: 0.85rem;
  align-self: center;
}
.category_btn
  .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 0.48 !important;
}
.centered-input input {
  text-align: center;
}
.map_wrap {
  // width: 330px;
  height: 300px;
  // position: relative;
  overflow: hidden;
  border-radius: 10px;
  padding-left: 5px;
  margin-bottom: 20px;
}
.radius_border {
  border: 1px solid #fff;
  border-radius: 5px;
}
.custom_typecontrol {
  background: white;
  position: absolute;
  top: 10px;
  right: 25px;
  overflow: hidden;
  width: 50px;
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 10;
  font-size: 12px;
  font-family: "Malgun Gothic", "맑은 고딕", sans-serif;
}
.custom_typecontrol span {
  display: block;
  width: 65px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.custom_typecontrol .btn {
  background: #fff;
  background: linear-gradient(#fff, #e6e6e6);
}
.custom_typecontrol .selected_btn {
  color: #fff;
  background: #e6e929;
  background: linear-gradient(#fe9900, #f79b13);
  // margin-right:10px;
}
.custom_typecontrol .selected_btn:hover {
  color: #fff;
}

.main_transaction {
  width: 100%;
  height: auto;
  margin-bottom: 30px;

  .tab {
    // width: 100%;
    // height: 60px;
    // background: #ff5112;
    border-radius: 5px;
    // @include flex-center;
    // margin-bottom: 15px;

    .v-tab {
      // color: #fff !important;
      position: relative;
      padding: 0 30px;
      cursor: pointer;
      // background: #ff5112;
      white-space: nowrap;

      &.after-bar::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 15px;
        background: #fff;
      }
    }
  }

  .coffee {
    width: 100%;
    height: auto;
    padding: 0 20px;
    @include flex-column;
    gap: 15px;
  }

  .v-tabs-slider-wrapper {
    height: 0 !important;
  }
}
</style>
